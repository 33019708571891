
import { Component, Prop, Vue } from 'vue-property-decorator'
import avatarDefault from './img/nav_icon_avatar_nor.png'
import { SiteStateModule } from '@/store/site_state'

@Component({
  name: 'UserAccount'
})
export default class UserAccount extends Vue {
  @Prop() avatar?: any

  get avatarUrl() {
    return this.avatar || avatarDefault
  }

  get account() {
    const user = SiteStateModule.userInfo
    if (user === null) return null
    return {
      accountId: user.accountId,
      accountLevel: user.accountLevel,
      accountName: user.accountName,
      accountType: user.accountType,
      userLevel: user.accountLevel,
      username: user.username
    }
  }

  private modifyPwd() {
    console.log('modifyPwd')
    this.$emit('ChangePassword')
  }

  async logout() {
    const data = await this.$http.post('/api/hyper_fast/sys/logout')
    if (data.data.code === 0) {
      SiteStateModule.logout()
    }
  }
}
